import '../css/projects.css';

import FolderOpenRoundedIcon from '@mui/icons-material/FolderOpenRounded';
import GitHubIcon from "@mui/icons-material/GitHub";
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';

import FadeInSection from './FadeInSection';
import useNav from "../hooks/useNav";


function Projects() {
    
  
    const projectsRef = useNav("projects");
    const projectsList = {
      "Minecraft Extension": {
          desc: "Java based Minecraft extension including QoL features and enhanced GUI for over 10,000 users.",
          techStack: "Java, JUnit, Mockito",
          link: "https://github.com/wxlkda/SkyBlock-Utils",
          open: ""
      },
      "FaceNet Implementation": {
          desc: "Implemented Google’s FaceNet paper utilizing triplet-loss in C++ for facial recognition.",
          techStack: "C++, TensorFlow",
          link: "https://github.com/wxlkda/google-facenet",
          open: ""
      },
      "Carma": {
          desc: "JavaScript app built during Google CSSI with cohort members, placing 1st out of 30 teams.",
          techStack: "JavaScript, TensorFlow, Firebase, React",
          link: "",
          open: ""
      },
      "Takumi AI": {
          desc: "An endless 2D runner game in PyGame, using a NEAT algorithm for evolving AI generations.",
          techStack: "Python",
          link: "https://github.com/wxlkda/TakumiAI",
          open: ""
      },
      "Data Visualizer": {
          desc: "A website used to visualize numerous algorithms on arrays, graphs, and binary trees.",
          techStack: "HTML / CSS, Javascript",
          link: "https://github.com/wxlkda/datavis",
          open: ""
      },
      "Personal Portfolio": {
          desc: "Personal portfolio made using React. (Yes it's this site, I needed to balance out the grid)",
          techStack: "React, Javascript, HTML/CSS",
          link: "https://github.com/wxlkda/realport",
          open: ""
      }
  };
    return (
      <div ref = {projectsRef} id="projects">
          <span className="projects-title">
            <b>/projects</b>
          </span>
        <div className="project-container">
            <ul className="projects-grid">
                {Object.keys(projectsList).map((key, i) => (
                <FadeInSection delay={`${i + 1}00ms`} key={i}>
                    <li className="projects-card">
                        <div className="card-header">
                            <div className="folder-icon">
                                <FolderOpenRoundedIcon style={{ fontSize: 35 }}></FolderOpenRoundedIcon>
                            </div>
                            <span className="external-links">
                                {projectsList[key]["link"] && (
                                  <a className="github-icon" href={projectsList[key]["link"]} rel="noopener noreferrer" target="_blank">
                                    <GitHubIcon style = {{fontSize: 23}}></GitHubIcon>
                                  </a>
                                )}
                                {projectsList[key]["open"] && (
                                  <a className="open-icon" href={projectsList[key]["open"]} rel="noopener noreferrer" target="_blank">
                                    <OpenInBrowserIcon style = {{fontSize: 25}}></OpenInBrowserIcon>
                                  </a>
                                )}
                            </span>
                        </div>

                        <div className="card-title">{key}</div>
                        <div className="card-desc">{projectsList[key]["desc"]}</div>
                        <div className="card-tech">{projectsList[key]["techStack"]}</div>
                    </li>
                </FadeInSection>
                ))}
            </ul>
        </div>
    </div>
    );
  };

export default Projects;