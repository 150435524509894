import '../css/experience.css';

import useNav from "../hooks/useNav";

function Experience() {
    const experienceRef = useNav("experience");
    return (
      <div ref = {experienceRef} id="experience">
        <span className="title">
          <b>/experience</b>
        </span>
        <div className="experience-info">
          I have experience (check my linkedin). Ill add it here and beautify it soon.... (this was last updated August 2024). <br></br><br></br>
          Icl I need to stop making this on a 2560 x 1080 monitor. Looks weird on a 1920x1080 (i think idk). If it does someone shoot me an email
        </div>
      </div>
    );
  };

export default Experience;